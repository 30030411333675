import { ContentLayout } from '@website/shared/ui/layouts/content-layout';
import { applicationImage } from './images/application';
import Typograf from '@common/components/common/Typograf';
import { useIntl } from 'react-intl';
import { messages } from './messages';
import { CustomImg } from '@common/shared/ui/custom-img';
import style from './style.module.scss';
import { analyticsDictionary, createAnalyticAttrs } from '@analytics';
const ApplicationBlock = () => {
    const intl = useIntl();
    return (<div {...createAnalyticAttrs(analyticsDictionary.landingApplicationShow, ['show'])}>
      <ContentLayout>
        <div className={style.card}>
          <div>
            <h3>
              <Typograf>{intl.formatMessage(messages.title)}</Typograf>
            </h3>
            <p>
              <Typograf>{intl.formatMessage(messages.text_p_1)}</Typograf>
            </p>
            <p>
              <Typograf>{intl.formatMessage(messages.text_p_2)}</Typograf>
            </p>
          </div>
          <CustomImg {...applicationImage} alt={intl.formatMessage(messages.img_alt)} className={style.appImg}/>
        </div>
      </ContentLayout>
    </div>);
};
export default ApplicationBlock;
