import { useState } from 'react';
import { useUnit } from 'effector-react';
import { analyticsDictionary, createAnalyticAttrs, createAnalyticShowAttribute } from '@analytics';
import { Icon20ArrowDownOutline } from '@vkontakte/icons';
import AnimatedLogo from '@common/components/common/AnimatedLogo/component';
import Typograf, { typografIt } from '@common/components/common/Typograf';
import { SubscriptionsModel } from '@common/entities/subscriptions';
import { ContentLayout } from '@website/shared/ui/layouts/content-layout';
import { useLogoObserver } from '@website/entities';
import { ApplyOfferButton } from '@website/features/apply-offer';
import style from './style.module.scss';
import { messages } from './messages';
import { useIntl } from 'react-intl';
const FirstBlock = ({ offer, offersState }) => {
    const intl = useIntl();
    const [ref, setRef] = useState(null);
    let title = ' <br> <br> ';
    let description;
    if (offer) {
        const hero = offer.widgets.hero;
        if (hero) {
            if (typeof hero.title === 'string') {
                title = hero.title;
            }
            else {
                title = intl.formatMessage(messages.title_default, { br: `<br />` });
            }
            if (typeof hero.description === 'string') {
                description = typografIt(hero.description);
            }
        }
    }
    const subtitle = offer?.widgets?.hero?.subtitle;
    useLogoObserver({ target: ref, threshold: 1 });
    const hasActiveSubscriptionStatus = useUnit(SubscriptionsModel.$hasActiveSubscriptionStatus);
    return (<ContentLayout className={style.banner} {...createAnalyticAttrs(analyticsDictionary.landingHeroShow, ['show'])}>
      <div className={style.bannerContent}>
        <div id={'js-btn-container'}>
          <div className={style.logo} ref={(el) => setRef(el)}>
            <AnimatedLogo />
          </div>

          <h1 className={style.bannerTitle} dangerouslySetInnerHTML={{
            __html: title,
        }}/>
          <p className={style.bannerSubtitle}>{!hasActiveSubscriptionStatus && <Typograf>{subtitle}</Typograf>}</p>
          <ApplyOfferButton className={style.firstBlock__button} offer={offer} offersState={offersState} type='hero' {...createAnalyticAttrs(analyticsDictionary.landingHeroGoToLkButtonClick, ['click'])} {...createAnalyticShowAttribute(analyticsDictionary.landingHeroGoToLkButtonClick)}/>
          <p className={style.terms} dangerouslySetInnerHTML={{
            __html: (!hasActiveSubscriptionStatus && description) || '',
        }}/>
        </div>
      </div>
      <div className={style.navigation}>
        <Icon20ArrowDownOutline />
        <Typograf>{intl.formatMessage(messages.bottom_text)}</Typograf>
      </div>
    </ContentLayout>);
};
export default FirstBlock;
