import { defineMessages } from 'react-intl';
export const messages = defineMessages({
    title_default: {
        id: 'title_default',
        defaultMessage: 'слушайте музыку и{br} аудиокниги{br} офлайн и без рекламы',
    },
    bottom_text: {
        id: 'bottom_text',
        defaultMessage: 'Больше о&nbsp;подписке',
    },
});
