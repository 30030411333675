import classNames from 'classnames';
import { analyticsDictionary, createAnalyticShowAttribute } from '@analytics';
import Typograf from '@common/components/common/Typograf';
import { ApplyOfferButton } from '@website/features/apply-offer';
import styles from './style.module.scss';
import { getBadgeIcon } from '@website/widgets/landings/main/offer-item/lib/badges';
const offerThemes = {
    default: styles.offer_theme_default,
    main: styles.offer_theme_main,
};
const priceThemes = {
    default: styles.price_theme_default,
    main: styles.price_theme_main,
};
const descriptionThemes = {
    default: styles.description_theme_default,
    main: styles.description_theme_main,
};
const OfferItem = (props) => {
    const { offer, offersState, className, index } = props;
    const card = offer.widgets.offer;
    const metrics = card?.metrics;
    const badge = card?.badge;
    const specification = offer.with;
    const button = (card?.buttons.length && card.buttons[0]) || null;
    const idOfferItem = offer.tags[0];
    const classNameOfferTheme = card && card.theme && offerThemes[card.theme];
    const classNamePriceTheme = card && card.theme && priceThemes[card.theme];
    const classNameDescriptionTheme = card && card.theme && descriptionThemes[card.theme];
    const BadgeIcon = getBadgeIcon(badge?.icon);
    return (<div id={idOfferItem} className={classNames(styles.offer, classNameOfferTheme, className)} {...createAnalyticShowAttribute(analyticsDictionary.landingOffersWidgetShow(metrics, index))}>
      {badge?.title && (<div className={styles.badge}>
          <BadgeIcon height={24} width={20}/>
          <span>
            <Typograf>{badge.title}</Typograf>
          </span>
        </div>)}
      {specification.price && (<div className={classNames(styles.price, classNamePriceTheme)}>
          <b>{card?.price || specification.price}</b>
          &thinsp;₽
        </div>)}
      <div className={classNames(styles.description, classNameDescriptionTheme)}>
        <Typograf>{card?.description || ''}</Typograf>
      </div>
      {button && (<div className={styles.buttonBlock}>
          <ApplyOfferButton offer={offer} offersState={offersState} className={styles.button} type='offer'/>
        </div>)}
    </div>);
};
export default OfferItem;
