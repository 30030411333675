import { defineMessages } from 'react-intl';
export const messages = defineMessages({
    title: {
        id: 'title',
        defaultMessage: 'слушайте ВКонтакте или&nbsp;в&nbsp;приложении VK&nbsp;Музыка',
    },
    text_p_1: {
        id: 'text_p_1',
        defaultMessage: 'Что бы вы ни выбрали, с вами будут сохранённые треки, эксклюзивные релизы артистов, подкасты, а также рекомендации: подборки от алгоритмов, пользователей, сообществ и кураторов.',
    },
    text_p_2: {
        id: 'text_p_2',
        defaultMessage: 'А ещё в приложении VK Музыка можно включить VK mix: бесконечный плейлист, который подстраивается под ваши вкусы.',
    },
    img_alt: {
        id: 'img_alt',
        defaultMessage: 'Приложение VK Музыка',
    },
});
