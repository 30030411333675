import { ContentLayout } from '@website/shared/ui/layouts/content-layout';
import style from './style.module.scss';
import classNames from 'classnames';
import { analyticsDictionary, createAnalyticAttrs } from '@analytics';
import { useIntl } from 'react-intl';
import { messages } from './messages';
import Typograf from '@common/components/common/Typograf';
const FeaturesBlock = () => {
    const intl = useIntl();
    return (<div className={style.features} {...createAnalyticAttrs(analyticsDictionary.landingFeaturesShow, ['show'])}>
      <ContentLayout>
        <div className={style.cardContainer}>
          <div className={classNames(style.card, style.card_item_1)} {...createAnalyticAttrs(analyticsDictionary.landingFeaturesItem('no_add'), ['show'])}>
            <h2 className={style.card__title}>
              <Typograf>{intl.formatMessage(messages.item_1_title)}</Typograf>
            </h2>
            <p className={style.card__text}>
              <Typograf>{intl.formatMessage(messages.item_1_text)}</Typograf>
            </p>
          </div>

          <div className={style.row}>
            <div className={classNames(style.card, style.card_item_2)} {...createAnalyticAttrs(analyticsDictionary.landingFeaturesItem('offline'), ['show'])}>
              <h2 className={style.card__title}>
                <Typograf>{intl.formatMessage(messages.item_2_title)}</Typograf>
              </h2>
              <p className={style.card__text}>
                <Typograf>{intl.formatMessage(messages.item_2_text)}</Typograf>
              </p>
            </div>

            <div className={classNames(style.card, style.card_item_3)} {...createAnalyticAttrs(analyticsDictionary.landingFeaturesItem('off_screen'), ['show'])}>
              <h2 className={style.card__title}>
                <Typograf>{intl.formatMessage(messages.item_3_title)}</Typograf>
              </h2>
              <p className={style.card__text}>
                <Typograf>{intl.formatMessage(messages.item_3_text)}</Typograf>
              </p>
            </div>
          </div>
        </div>
      </ContentLayout>
    </div>);
};
export default FeaturesBlock;
