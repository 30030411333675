import application375MiwWebp from './application@375miw.webp';
import application375MiwPng from './application@375miw.png';
import applicationWebp from './application.webp';
import applicationPng from './application.png';
export const applicationImage = {
    src: applicationPng.src,
    width: 480,
    height: 330,
    sources: [
        { width: 928, height: 966, media: '(min-width: 375px)', srcSet: application375MiwWebp.src },
        { width: 928, height: 966, media: '(min-width: 375px)', srcSet: application375MiwPng.src },
        { width: 480, height: 330, srcSet: applicationWebp.src },
        { width: 480, height: 330, srcSet: applicationPng.src },
    ],
};
