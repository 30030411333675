import { Icon16Education, Icon16Flash, Icon16Music, Icon16Video } from '@vkontakte/icons';
const offerBadgeIcons = {
    student: Icon16Education,
    move: Icon16Video,
    flash: Icon16Flash,
    music: Icon16Music,
};
export const getBadgeIcon = (badgeIcon) => {
    if (!badgeIcon) {
        return () => null;
    }
    if (!offerBadgeIcons[badgeIcon]) {
        return () => null;
    }
    return offerBadgeIcons[badgeIcon];
};
