import { defineMessages } from 'react-intl';
export const messages = defineMessages({
    item_1_title: {
        id: 'item_1_title',
        defaultMessage: 'без рекламы',
    },
    item_1_text: {
        id: 'item_1_text',
        defaultMessage: 'Музыка, подкасты и ничего лишнего',
    },
    item_2_title: {
        id: 'item_2_title',
        defaultMessage: 'без интернета',
    },
    item_2_text: {
        id: 'item_2_text',
        defaultMessage: 'Скачивайте всё, что нравится, и слушайте, даже когда нет связи',
    },
    item_3_title: {
        id: 'item_3_title',
        defaultMessage: 'с&nbsp;выключенным экраном',
    },
    item_3_text: {
        id: 'item_3_text',
        defaultMessage: 'Ничего не остановится, когда вы свернёте приложение или заблокируете экран',
    },
});
